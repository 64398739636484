import React from 'react';
import styled, { css } from 'styled-components';
import Headline from '@components/atoms/Headline/Headline';
import Paragraph from '@components/atoms/Paragraph/Paragraph';
import ContactForm from '@components/molecules/ContactForm/ContactForm';
import Localization from '@assets/svg/contact_localization.svg';
import Telephone from '@assets/svg/contact_telephone.svg';
import Mail from '@assets/svg/contact_mail.svg';

interface StyledProps {
  $isTopBox?: boolean;
  $isBottomBox?: boolean;
}

const SectionWrapper = styled.section`
  padding: 0 15px 30vw 15px;
  position: relative;
  @media (min-width: 576px) {
  }
  @media (min-width: 1025px) {
    max-width: 1250px;
    margin: 0 auto;
  }
`;
const HeadlineWrapper = styled.div`
  display: flex;
  justify-content: center;
  h2 {
    text-align: center;
  }

  @media (min-width: 1025px) {
    padding: 0 0 200px 0;
  }
`;
const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  @media (min-width: 1025px) {
    background-color: ${({ theme }) => theme.lightGrey};
    flex-direction: row;
    border-radius: 0 100px 100px 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100vw - 1250px);
      height: 100%;
      transform-origin: left 50%;
      transform: translateX(-100%);
      z-index: -1;
      background-color: ${({ theme }) => theme.lightGrey};
    }
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: 1025px) {
    width: 40%;
    position: relative;
    margin: 250px 0;
  }
`;

const StyledBox = styled.div<StyledProps>`
  padding: 10px;
  margin-bottom: 80px;
  background-color: #fff;
  width: 280px;
  height: 300px;
  border-radius: 100px 0 100px 0;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ $isTopBox }) =>
    $isTopBox &&
    css`
      @media (min-width: 1025px) {
        position: absolute;
        top: -380px;
        left: 20%;
      }
    `}
  ${({ $isBottomBox }) =>
    $isBottomBox &&
    css`
      @media (min-width: 1025px) {
        position: absolute;
        bottom: -380px;
        left: 20%;
      }
    `}

  img {
    width: 80px;
  }
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.primary};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const StyledBoxParagraph = styled(Paragraph)`
  text-align: center;
  color: ${({ theme }) => theme.primary};
  margin-top: 15px;

  span {
    font-weight: ${({ theme }) => theme.bold};
    font-size: ${({ theme }) => theme.bodyL};

    a {
      text-decoration: none;
      color: ${({ theme }) => theme.primary};
    }
  }
`;

const StyledBgLine = styled.span`
  position: absolute;
  top: 0;
  left: 50%;
  width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.grey};
  transform: translateX(-50%);
  z-index: -1;
`;

const StyledBgLineLeft = styled(StyledBgLine)`
  left: 20%;
  @media (min-width: 1025px) {
    left: 35%;
  }
`;
const StyledBgLineRight = styled(StyledBgLine)`
  left: 80%;

  @media (min-width: 1025px) {
    left: 65%;
  }
`;

const Contact = () => {
  return (
    <SectionWrapper id="contact">
      <HeadlineWrapper>
        <Headline
          as="h2"
          $isHeadline2
          $isMainColor
          data-aos="fade-down"
          data-aos-offset="200"
        >
          Masz jakieś pytania? <br /> Skontaktuj się z nami!
        </Headline>
      </HeadlineWrapper>
      <ContactWrapper>
        <ContactInfo className="contact-info">
          <StyledBox
            $isTopBox
            data-aos="title-animation"
            data-aos-anchor=".contact-info"
          >
            <a href="https://goo.gl/maps/pv2nSrCPD24NvEzt8" target="blank">
              <img src={Localization} alt="" />
              <StyledBoxParagraph>
                Biuro sprzedaży Bud-Pol: <br />
                <span>Napoleońska 95,06-500 Mława</span>
              </StyledBoxParagraph>
            </a>
          </StyledBox>

          <StyledBox
            data-aos="title-animation"
            data-aos-anchor=".contact-info"
            data-aos-delay="200"
          >
            <a href="tel:604960156">
              <img src={Telephone} alt="" />
              <StyledBoxParagraph>
                Numer kontaktowy: <br />
                <span>+48 604 960 156</span>
              </StyledBoxParagraph>
            </a>
            <a href="tel:607567479">
              <StyledBoxParagraph>
                <span>+48 607 567 479</span>
              </StyledBoxParagraph>
            </a>
          </StyledBox>

          <StyledBox
            $isBottomBox
            data-aos="title-animation"
            data-aos-anchor=".contact-info"
            data-aos-delay="400"
          >
            <a href="mailto:biuro@ekowkra.pl">
              <img src={Mail} alt="" />
              <StyledBoxParagraph>
                Adres e-mail: <br />
                <span>biuro@ekowkra.pl</span>
              </StyledBoxParagraph>
            </a>
          </StyledBox>
        </ContactInfo>
        <ContactForm />
      </ContactWrapper>
      <StyledBgLine></StyledBgLine>
      <StyledBgLineLeft></StyledBgLineLeft>
      <StyledBgLineRight></StyledBgLineRight>
    </SectionWrapper>
  );
};

export default Contact;
